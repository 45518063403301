import React from 'react'

import sweet_bakery_kakao from '../images/products/sweet-bakery-kakao.jpg'
import sweet_bakery_mesavina from '../images/products/sweet-bakery-mesavina.jpg'
import waltz_bakery_mesavina from '../images/products/waltz-bakery-mesavina.jpg'
import waltz_bakery_mesavina1 from '../images/products/waltz-bakery-mesavina1.jpg'
import waltz_bakery_kakao from '../images/products/waltz-bakery-kakao.jpg'

class BakeryCream extends React.Component{
    render(){
        return(
            <section id="proizvodi" className="hero products-page default-page">
                <div className="container padding-0 margin-0">
                    <div className="columns margin-0 main">
                        <div className="column is-4 static-part is-left padding-0">
                            <div className="content">
                                <div className="title">CREAM</div>
                                <span className="zigzag"></span>
                                <p className="text">Cream namenjen je za spravljanje najfinih čudesa za koje su 
                                zaduženi naši mađioničari koji prave slastice za najlepša sjećanja. Pokušajte.</p>
                            </div>
                        </div>{/*STATIC PART END */}
                        <div className="column is-8 content-part is-right padding-0">
                            <div className="columns is-multiline inner margin-0">
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_bakery_mesavina} alt="Waltz bakery mešavina" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_bakery_mesavina1} alt="Waltz bakery mešavina" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_bakery_kakao} alt="Waltz bakery kakao" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={sweet_bakery_mesavina} alt="Sweet bakery mešavina"/>
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={sweet_bakery_kakao} alt="Sweet bakery kakao" />
                                </div>
                            </div>
                        </div>{/* PRODUCTS PART END */}
                    </div>{/* COLUMNS END */}
                </div>{/* CONTAINER END */}
            </section>/* PRODUCTS PAGE SECTION END */
        );
    }
}

export default BakeryCream